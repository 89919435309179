@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fadeMe {
  animation:fadeOut 2s linear;
}

@keyframes fadeOut {
  0% {
    opacity:1
  }
  100% {
    opacity:0;
  }
}

.fadeMe.none {
  display:none
}

.clicktip {
  opacity: 0;
}

.has-clicktip:active .clicktip {
  @apply fadeMe z-50
}

@layer utilities {
  .mtp-15 {
    margin-top: 15%;
  }

  .mbp-15 {
    margin-bottom: 15%;
  }

  .myp-15 {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .mtp-10 {
    margin-top: 10%;
  }

  .mbp-10 {
    margin-bottom: 10%;
  }

  .myp-10 {
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .recorder-margin-y {
    margin-top: min(15%, 64px);
    margin-bottom: min(15%, 64px);
  }

  @responsive {
    .text-shadow {
      text-shadow: 0 2px 4px rgba(0,0,0,0.10);
    }
  
    .text-shadow-md {
      text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
    }
  
    .text-shadow-lg {
      text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
    }
  
    .text-shadow-none {
      text-shadow: none;
    }
  }  
}